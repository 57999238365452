import React from 'react';

import { Link } from 'gatsby';

import Arrow from '../../../assets/img/icons/ic-chevron-right.svg';

import styles from './BreadcrumbsItem.module.scss';

const BreadcrumbsItem = ({
  href, name, isLast = false, isActive, target = '_blank',
}) => (
  <div className={styles.breadcrumbsItem}>
    <a
      className={`${styles.link} ${isActive ? styles.linkActive : ''} text-truncate`}
      href={href}
      target={target}
      rel="noopener noreferrer"
    >
      {name}
    </a>
    {!isLast && !isActive && <Arrow className={styles.arrow} />}
  </div>
);

export default BreadcrumbsItem;
