import React, { useEffect, useMemo, useState } from 'react';
import { navigate } from 'gatsby';
import qs from 'qs';
import MetaTags from 'react-meta-tags';

import {
  OFFERS_TAB_INDEX,
  PROPERTY_DETAILS_TABS,
  SEARCH_RESULT_PROPERTY_DETAILS_TABS,
} from '../../constans/search';
import Tabs from '../Tabs/Tabs';
import SearchFilters from '../SearchFilters/SearchFilters';
import Panel from '../Tabs/Panel';
import PropertyDetailsSearchResult from '../PropertyDetails/PropertyDetailsSearchResult/PropertyDetailsSearchResult';
import SearchResultRoomsList from '../RoomItem/SearchResultRoomsList/SearchResultRoomsList';
import SearchResultOffersList from '../RoomItem/SearchResultOffersList/SearchResultOffersList';
import RoomsService from '../../services/rooms.service';
import StorageService from '../../services/storage.service';
import { toggleBodyStylesForModal } from '../../helpers/helper-methods';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import ROLES from '../../constans/roles';
import { DEFAULT_NUMBER_OF_ROOMS } from '../../constans/validation';
import Layout from '../Layout/Layout';
import { Consumer, Provider } from '../Tabs/Context';
import BreadcrumbsActivity from '../BreadcrumbsActivity/BreadcrumbsActivity';
import PropertyInfoTab from '../PropertyDetails/PropertyInfoTab/PropertyInfoTab';

import styles from '../SearchResultPropertyInfo/SearchResultPropertyInfo.module.scss';
import { BOOK_BUTTON_TEXT } from '../../constans/texts/texts';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import BookPropertyButton from '../BookPropertyButton/BookPropertyButton';
import { StructuredData } from '../StructuredData/StructuredData';
import SEO from '../Seo';
import SignUpModal from '../AutorizationModal/SignUpModal';

function SearchResultPropertyInfoStatic({
  containerStyles,
  switchersStyles,
  requestId,
  GoBackBtn,
  filters,
  setActiveIndex,
  filterContainerStyles,
  currency,
  checkin,
  checkout,
  adultGuests,
  kidGuests,
  numberOfRooms = DEFAULT_NUMBER_OF_ROOMS,
  kids,
  pageContext,
}) {
  const { property, detailsView } = pageContext;
  const storageService = useMemo(() => StorageService(), []);
  const [rooms, setRooms] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [isModalShown, setIsModalShown] = useState(false);

  const toggleSignInModal = () => {
    toggleBodyStylesForModal();
    setIsModalShown((prevState) => !prevState);
  };

  const getRooms = async (propertyId) => {
    const { data } = await RoomsService()
      .getRoomsByPropertyId({ propertyId, shortResponse: true });

    const processedData = data.map(({ title, id }) => ({
      id,
      title,
      value: `${id}`,
    }));

    setRooms(processedData);
  };

  const handleRoomOffersClick = (id) => {
    setActiveIndex(OFFERS_TAB_INDEX);
    setSelectedRooms([`${id}`]);
  };

  const handleAllOffersClick = () => {
    setActiveIndex(OFFERS_TAB_INDEX);
    setSelectedRooms([]);
  };

  useEffect(() => {
    if (property.id) {
      getRooms(property.id);
    }
  }, [property.id]);

  const handleSuccess = (offerId) => {
    const query = qs.stringify({
      propertyId: property.id,
      offerId,
      currency,
      checkin,
      checkout,
      adultGuests,
      kidGuests,
      numberOfRooms,
      kids,
    });

    navigate(`/customers/bookings/create?${query}`);
  };

  const handleBookClick = (offerId) => {
    const { role } = storageService.get(LOCALSTORAGE_KEYS.USER, { role: ROLES.GUEST });

    if (role === ROLES.RETAIL_CUSTOMER) {
      handleSuccess(offerId);

      return;
    }

    toggleSignInModal();
  };

  return (
    <Layout mainClasses="column-flexed-wrapper">
      <MetaTags>
        {property?.metaDescription && <meta id="meta-description" name="description" content={property?.metaDescription} />}
        {property?.metaKeywords && <meta id="meta-keywords" name="keywords" content={property?.metaKeywords} />}
      </MetaTags>
      <StructuredData article property={property} />
      <SEO title="Koralgo" component={property} />
      <div className="flex flex-grow-1 flex-h-start">
        <div className={`container property-edit-wrapper ${styles.pageWrapper}`}>
          <div className={styles.wrapperBreadcrumbs}>
            <BreadcrumbsActivity
              country={property.country}
              region={property.region}
              city={property.city}
              district={property.district}
              name={property.title}
              isActivity={false}
            />
          </div>
          <div className={containerStyles}>
            {GoBackBtn}
            <SignUpModal
              isShown={isModalShown}
              toggleModal={toggleSignInModal}
              isSignIn={false}
            />

            <div className={styles.headerButtonsContainer}>
              <InfoTooltip tooltipIconClassName={styles.tooltip}>
                {BOOK_BUTTON_TEXT}
              </InfoTooltip>
              <BookPropertyButton
                details={property}
                buttonWrapperClasses={styles.bookActivityButtonWrapper}
              />
            </div>

            <Provider>
              <Consumer>
                {() => (
                  <Tabs
                    switcherStyles={styles.switcher}
                    className={styles.tabContainer}
                    switchers={
                      detailsView ? PROPERTY_DETAILS_TABS : SEARCH_RESULT_PROPERTY_DETAILS_TABS
                    }
                    switchersClassName={`${styles.switchersWrapper} ${switchersStyles}`}
                    switchButtonClassName={styles.switchButtonClassName}
                    activeClassName={styles.activeClassName}
                    tabsWithFiltersIds={[OFFERS_TAB_INDEX]}
                    Filter={(
                      !detailsView && (
                        <SearchFilters
                          containerClassName={filterContainerStyles}
                          togglerClassName={styles.toggler}
                          initialValues={{ rooms: [...selectedRooms] }}
                          resetState={{ rooms: [] }}
                          roomTypes={rooms}
                          roomTypesName="rooms"
                          onSubmit={(values) => setSelectedRooms(values?.rooms)}
                        />
                      ))}
                  >
                    <Panel tabId={0}>
                      <PropertyDetailsSearchResult
                        detailsView={detailsView}
                        onAllOffersClick={handleAllOffersClick}
                        propertyId={property.id}
                        galleryInnerStyles={styles.galleryInner}
                      />
                    </Panel>
                    <Panel tabId={1}>
                      <PropertyInfoTab
                        propertyId={property.id}
                      />
                    </Panel>
                    <Panel tabId={2}>
                      <SearchResultRoomsList
                        detailsView={detailsView}
                        onRoomOffersClick={handleRoomOffersClick}
                        propertyId={property.id}
                        requestId={requestId}
                      />
                    </Panel>
                    {!detailsView && (
                      <Panel tabId={3}>
                        <SearchResultOffersList
                          filters={filters}
                          requestId={requestId}
                          activePropertyId={property.id}
                          selectedRooms={selectedRooms}
                          onBookClick={handleBookClick}
                        />
                      </Panel>
                    )}
                  </Tabs>
                )}
              </Consumer>
            </Provider>
          </div>
        </div>
      </div>
    </Layout>
  );
}

SearchResultPropertyInfoStatic.defaultProps = {
  containerStyles: '',
  GoBackBtn: null,
  switchersStyles: '',
  filterContainerStyles: 'col-9 col-md-5 col-lg-4',
};

export default SearchResultPropertyInfoStatic;
